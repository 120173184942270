<template>
  <div>
    <b-row>
      <b-col cols="12">

        <filters
          :add="false"
          :search="true"
          @filtered-items="filter"
        />
      </b-col>
      <b-col cols="12">

        <b-table
          hover
          show-empty
          :fields="fields"
          :items="GET_MODIFIED_BRANCH"
          style-class="slots_text_align vgt-table bordered"
        >

          <template
            #empty
          >
            <div class="text-center text-secondary">
              Нет данных для просмотра
            </div>
          </template>
          <template #cell(status)="{value}">
            <b-badge :variant="value === 'created' ? 'success' : value ==='edited' ? 'warning' : 'danger'">
              {{ value }}
            </b-badge>
          </template>
          <template
            #cell(actions)="data"
          >
            <div class="d-flex align-items-center no-wrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon mr-50 p-25"
                size="sm"
                @click="accept(data.item)"
              >
                <feather-icon
                  size="22"
                  icon="CheckIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon mr-50 p-25"
                size="sm"
                @click="editBranch(data.item)"
              >
                <feather-icon
                  size="22"
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon p-25"
                size="sm"
                @click="cancel(data.item)"
              >
                <feather-icon
                  size="22"
                  icon="XIcon"
                />
              </b-button>
            </div>
          </template>

        </b-table>

        <!-- pagination -->
        <!-- slot-scope="props" -->
        <template>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">{{ $t('general.show_from') }}</span>
              <b-form-select
                v-model="params.page_size"
                :options="['1', '5', '10', '15']"
                class="mx-1"
                @change="changePerPage"
              />
              <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            <!-- из {{ props.total }} -->
            </div>
            <div>
              <b-pagination
                v-model="params.page"
                :total-rows="1"
                :per-page="params.page_size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </b-col>
    </b-row>
    <cancel-or-accept
      :data-id="dataId"
      :is-cancel="isCancel"
      :branch-data="branchData"
      @ok="submit"
    />

    <AddModal
      :branch-data="editBranchData"
      :is-edit="isEdit"
    />
  </div>
</template>

<script>
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import AddModal from '../../../BranchesList/components/AddModal.vue'
import CancelOrAccept from '@/components/CancelOrAcceptModal.vue'
import filters from '@/components/filters.vue'

export default {
  components: {
    BTable,
    BFormSelect,
    BPagination,
    filters,
    AddModal,
    BButton,
    BRow,
    BBadge,
    BCol,
    CancelOrAccept,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      branchData: {},
      isCancel: false,
      isEdit: true,
      editBranchData: {},
      dataId: null,
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      fields: [
        {
          label: 'Название филиала',
          key: 'title',
        },
        {
          label: 'Лимит пользователей (Ед.)',
          key: 'capacity',
        },
        {
          label: 'Лимит памяти (GB)',
          key: 'storage',
        },
        {
          label: 'Дата создания',
          key: 'access_start_date',
        },
        {
          label: 'Дата окончения',
          key: 'access_end_date',
        },
        {
          label: 'Статус',
          key: 'status',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('branches', ['GET_MODIFIED_BRANCH']),
  },
  mounted() {
    this.FETCH_MODIFIED_BRANCH_LIST()
  },
  methods: {
    ...mapActions('branches', ['FETCH_MODIFIED_BRANCH_LIST', 'UPDATE_BRANCH', 'FETCH_REJECT_BRANCH_LIST']),
    // отвравления решении
    submit(id, comment, status) {
      if (!comment) {
        if (!this.isCancel) {
          this.UPDATE_BRANCH({ id: this.dataId, status: 'approved' })
            .then(() => {
              this.$_successToast('approved')
              this.FETCH_MODIFIED_BRANCH_LIST()
              this.$bvModal.hide('CancelOrAccept')
            })
            .catch(() => {
              this.$_errorToast('Ошибка')
            })
        } else {
          this.UPDATE_BRANCH({ id: this.dataId, status: 'rejected' })
            .then(() => {
              this.$_successToast('rejected')
              this.FETCH_MODIFIED_BRANCH_LIST()
              this.FETCH_REJECT_BRANCH_LIST()
              this.$bvModal.hide('CancelOrAccept')
            })
            .catch(() => {
              this.$_errorToast('Ошибка')
            })
        }
      }
      console.log(status)
    },
    // откривание модаля потвердить
    accept({ id }) {
      this.isCancel = false
      this.dataId = id
      this.$nextTick(() => {
        this.$bvModal.show('CancelOrAccept')
      })
    },
    // откривание модаля отклонить
    cancel({ id }) {
      this.dataId = id
      this.isCancel = true
      this.$nextTick(() => {
        this.$bvModal.show('CancelOrAccept')
      })
    },
    editBranch(data) {
      this.editBranchData = data
      this.isEdit = true
      this.$nextTick(() => {
        this.$bvModal.show('AddModalBranch')
      })
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      // this.FETCH_USERS_LIST(this.params)
    },
    changePage(value) {
      this.params.page = value
      // this.FETCH_USERS_LIST(this.params)
    },
    filter(val) {
      this.params.search = val.search
      // this.FETCH_USERS_LIST(val)
    },
  },
}
</script>

<style>

</style>
