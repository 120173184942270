<template>
  <div>
    <b-card class="card__tabs">
      <b-tabs>
        <b-tab
          active
          :title="$t('Ожидающие решения')"
        >
          <b-card-text>
            <WaitingAprove />
          </b-card-text>
        </b-tab>

        <b-tab
          :title="$t('Отклоненые данние')"
        >
          <b-card-text>
            <CanceledDataTable />
          </b-card-text>
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'
import CanceledDataTable from './components/CanceledData/CanceledDataTable.vue'
import WaitingAprove from './components/WaitingForAprove/WaitingAprove.vue'

export default {
  name: 'References',
  components: {
    BCardText,
    BTabs,
    BTab,
    BCard,
    WaitingAprove,
    CanceledDataTable,
  },
}
</script>
