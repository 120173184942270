<template>
  <b-modal
    id="CancelOrAccept"
    :title="isCancel? 'Отклонить' : $t('Потвердить')"
    size="sm"
    :ok-variant="isCancel? 'secondary' : 'success'"
    cancel-variant="danger"
    :ok-title="isCancel? 'Отклонить' : $t('Потвердить')"
    cancel-title="Отменить"
    centered
    no-close-on-backdrop
    @ok.prevent="$emit('ok', id, comment, status)"
    @cancel="$bvModal.hide('CancelOrAccept')"
  >
    <b-row>
      <b-col
        v-if="isCancel"
        cols="12"
      >
        <h3>Вы действительно хотите отклонить</h3>
      </b-col>
      <b-col
        cols="12"
        class="mt-2"
      >
        <h6>Названия филлиала:</h6>
        <span>{{ branchData.branch_title }}</span>
      </b-col>
      <b-col
        cols="6"
        class="mt-2"
      >
        <h6>Лимит пользователей:</h6>
        <span>{{ branchData.users_limit }}</span>
      </b-col>
      <b-col
        cols="6"
        class="mt-2"
      >
        <h6>Лимит памяти:</h6>
        <span>{{ branchData.memory_limit }}</span>
      </b-col>
      <b-col
        cols="6"
        class="mt-2"
      >
        <h6>Дата создания:</h6>
        <span>{{ branchData.memory_limit }}</span>
      </b-col>
      <b-col
        cols="6"
        class="mt-2"
      >
        <h6>Дата окончания:</h6>
        <span>{{ branchData.memory_limit }}</span>
      </b-col>
      <!-- <b-col
        cols="6"
        class="mt-2"
      >
        <h6>Статус</h6>
        <b-form-checkbox
          v-model="status"
          :checked="branchData.status"
          name="check-button"
          switch
          inline
        />
      </b-col> -->
      <b-col
        v-if="isCancel"
        cols="12"
        class="mt-2"
      >
        <b-form-group label="комментарии">
          <b-form-textarea
            id="textarea-default"
            v-model="comment"
            placeholder="Textarea"
            rows="2"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  // BFormCheckbox,
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    // BFormCheckbox,
    BFormTextarea,
    BFormGroup,
  },
  props: {
    dataId: {
      type: Number,
      default: null,
    },
    isCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    branchData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      comment: null,
      status: false,
      id: this.dataId,
    }
  },
}
</script>

<style>

</style>
